<template>
	<div>
		
		<CCard class="p-4">
		<CRow>
			
			<CCol lg="6">
					<h5>Bookings ({{count}})</h5>
			</CCol>
	     		 <CCol lg="6">
					  <CButton size="sm" style="float: right;" :class="'float-lg-right block '+showCollapse ? 'collapsed' : null"
			                 :aria-expanded="showCollapse ? 'true' : 'false'"
			                 aria-controls="collapse1"
			                 @click="showCollapse = !showCollapse"
			                 color="info"><i class="fa fa-search"></i>Filter<i :class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i></CButton>
			        <CButton 
						v-if="config.getPermission('booking').create"
						size="sm" style="margin-right: 10px" 
						color="success" target="_blank " href="#/main/create_booking" 
						class="float-lg-right"><i class="fa fa-plus"></i>Add Booking
					</CButton>
			    </CCol>
		</CRow>
		<CRow> 
				<CCol lg="12">
					<CCollapse id="collapse1" :show="showCollapse" class="mt-2">
				        <CCard class="p-4">
				          <Search @depot-search-query="search"/>
				        </CCard>
				      </CCollapse> 
				</CCol>
		</CRow> 
		<hr>

		<CRow>			 
		      <CCol lg="12" >  
				<div class="table-responsive-sm table-responsive-md">
	      		<CTabs variant="pills" :active-tab="active_tab">
	      			<CTab title="All" @click.native="tabChanged($event, null)">
						
				      	<CDataTable
				      		:loading="all_isloading"
					      	:items="dataList.data"
					      	:fields="fields"
							hover
							outlined
							small
							fixed
							@pagination-change="paginationChange"
							:itemsPerPageSelect="{values: [20,50,100]}"
      						:itemsPerPage="limit"
							
							
					    >  
						
							
					    	<template #status="{item}">
								<td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}</CBadge>
									<br>
									<small>{{  item.client_billing ? item.client_billing.client_billing_no : '' }}</small>
         						 </td>
					      	</template>
					      	<template #action="{item}">
						        <td style="padding: 5px; width: 100px">
						        	<CDropdown 
						                toggler-text="Select"  
										size="sm"
						                color="info"
						            >
						            	<CDropdownItem v-if="config.getPermission('booking').view" @click="viewModal(item)"><i class="fa fa-eye" > View </i></CDropdownItem>
						            	<CDropdownItem v-if="config.getPermission('booking').update 
											&& hide_when(['dispatcher','finance staff','fleet staff','fleet manager','rw hr staff'], //hide button for roadwise only
											['delivered','under review','dispatched'].indexOf(item.status) > -1)" 
											@click="updateModal(item)"> <i class="fa fa-pencil-square-o"> 
											Update </i>
										</CDropdownItem>  
						                <CDropdownItem @click="openApproveModal(item)" v-if="item.status == 'under review' && config.getPermission('booking').approve && company_type != 'trucking'"> <i class="fa fa-check-square-o"> Approve </i></CDropdownItem> 
						                <CDropdownItem @click="openDispatchModal(item)" v-if="
						                	((item.status == 'approved' || item.status == 'under review' ) &&
						                	config.getPermission('booking').dispatch && 
						                	company_type == 'trucking') ||
						                	((item.status == 'approved') &&
						                	config.getPermission('booking').dispatch && 
						                	company_type != 'trucking')"
						                > 
						                	<i class="fa fa-car"> Dispatch </i> 
						                </CDropdownItem> 
										<CDropdownItem v-if="(item.status == 'completed' )" @click="bookAgain(item)"> <i class="fa fa-list-alt"> Book Again </i> </CDropdownItem> 
						                <CDropdownItem @click="openDeliverModal(item)" v-if="item.status == 'dispatched' && config.getPermission('booking').deliver"> <i class="fa fa-paper-plane"> Deliver </i> </CDropdownItem> 
						                <CDropdownItem @click="openCompleteModal(item)" v-if="item.status == 'delivered' && config.getPermission('booking').complete"> <i class="fa fa-list-alt"> Complete </i> </CDropdownItem> 
						                <CDropdownItem v-if="config.getPermission('booking').cancel && item.status !== 'cancelled'" @click="cancelBooking(item)"> <i class="fa fa-window-close-o"> Cancel </i></CDropdownItem> 
						                <CDropdownItem v-if="config.getPermission('booking').delete" @click="confirmDelete(item)"><i class="fa fa-trash"> Delete </i> </CDropdownItem> 
						            </CDropdown>
						        </td>
					      	</template>
					    </CDataTable> 
					    <CPagination
				          	:active-page.sync="currentPage"
				          	:pages="Math.ceil(dataList.total / limit)"
				          	:activePage="currentPage"
				          	@update:activePage="updatePage($event, null)"
				        />
				    </CTab>
				    <CTab title="Under Review" @click.native="tabChanged($event, 'under review')">
				    	<br/>
						<!-- <CCol lg="12" class="text-center" v-if="isLoading"> 
							<div class="spinner-border m-5" role="status">
								<span class="sr-only">Loading...</span>
							</div>
						</CCol> -->
				      	<CDataTable 
				      		:loading="all_isloading"
					      	:items="dataListUnderReview.data"
					      	:fields="fields"
							hover
							outlined
							small
							fixed
							@pagination-change="paginationChange"
							:itemsPerPageSelect="{values: [20,50,100]}"
      						:itemsPerPage="limit"
					    >   
						<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}</CBadge>
         						 </td>
					      	</template>
					      	<template #action="{item}">
						        <td style="padding: 5px; width: 100px">
						        	<CDropdown 
						                toggler-text="Select"  
										size="sm"
						                color="info"
						            >
									    <CDropdownItem v-if="config.getPermission('booking').view" @click="viewModal(item)"><i class="fa fa-eye" > View </i></CDropdownItem>
						            	<CDropdownItem v-if="config.getPermission('booking').update 
										&& hide_when(['dispatcher','finance staff','fleet staff','fleet manager','rw hr staff'], //hide button for roadwise only
										['delivered','under review','dispatched'].indexOf(item.status) > -1)" 
										 @click="updateModal(item)"> <i class="fa fa-pencil-square-o"> Update </i> </CDropdownItem>  
						                <CDropdownItem @click="openApproveModal(item)" v-if="item.status == 'under review' && config.getPermission('booking').approve && company_type != 'trucking'"> <i class="fa fa-check-square-o"> Approve </i></CDropdownItem> 
						                <CDropdownItem @click="openDispatchModal(item)" v-if="item.status == 'under review' && config.getPermission('booking').dispatch && company_type == 'trucking'"> <i class="fa fa-check-square-o"> Dispatch </i></CDropdownItem> 
						                <CDropdownItem v-if="config.getPermission('booking').cancel && item.status !== 'cancelled'" @click="cancelBooking(item)"> <i class="fa fa-window-close-o"> Cancel </i></CDropdownItem> 
						                <CDropdownItem v-if="config.getPermission('booking').delete" @click="confirmDelete(item)"><i class="fa fa-trash"> Delete </i> </CDropdownItem> 
						                <CDropdownItem v-if="config.getPermission('booking').cancel" @click="cancelBooking(item)"> <i class="fa fa-window-close-o"> Cancel </i> </CDropdownItem> 
			
						            </CDropdown>
						        </td>
					      	</template>
					    </CDataTable> 
					    <CPagination
				          	:active-page.sync="currentPageUnderReview"
				          	:pages="Math.ceil(dataListUnderReview.total / limit)"
				          	:activePage="currentPageUnderReview"
				          	@update:activePage="updatePage($event, 'under review')"
				        />
				    </CTab>

				    <CTab title="Approved" v-if="company_type !== 'trucking'" @click.native="tabChanged($event, 'approved')">
				    	<br/>
						
				      	<CDataTable 
				      		:loading="all_isloading"
					      	:items="dataListApproved.data"
					      	:fields="fields"
							hover
							outlined
							small
							fixed
							@pagination-change="paginationChange"
							:itemsPerPageSelect="{values: [20,50,100]}"
      						:itemsPerPage="limit"
							
					    >   
							<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}</CBadge>
         						 </td>
					      	</template>
					      	<template #action="{item}">
						        <td style="padding: 5px; width: 100px">
						        	<CDropdown 
						                toggler-text="Select"  
										size="sm"
						                color="info"
						            >
										<CDropdownItem v-if="config.getPermission('booking').view" @click="viewModal(item)"><i class="fa fa-eye" > View </i></CDropdownItem>
						            	<CDropdownItem v-if="config.getPermission('booking').update" @click="updateModal(item)"> <i class="fa fa-pencil-square-o"> Update </i> </CDropdownItem>  
            							<CDropdownItem @click="openDispatchModal(item)" v-if="item.status == 'approved' && config.getPermission('booking').dispatch"> <i class="fa fa-car"> Dispatch </i> </CDropdownItem> 
						                <CDropdownItem v-if="config.getPermission('booking').cancel && item.status !== 'cancelled'" @click="cancelBooking(item)"> <i class="fa fa-window-close-o"> Cancel </i></CDropdownItem> 
						                <CDropdownItem v-if="config.getPermission('booking').delete" @click="confirmDelete(item)"><i class="fa fa-trash"> Delete </i> </CDropdownItem> 
						            </CDropdown>
						        </td>
					      	</template>
					    </CDataTable> 
					    <CPagination
				          	:active-page.sync="currentPageApproved"
				          	:pages="Math.ceil(dataListApproved.total / limit)"
				          	:activePage="currentPageApproved"
				          	@update:activePage="updatePage($event, 'approved')"
				        />
				    </CTab>

				  
				    <CTab title="Dispatched" @click.native="tabChanged($event, 'dispatched')">
				    	<br/>
						
				      	<CDataTable 
				      		:loading="all_isloading"
					      	:items="dataListDispatched.data"
					      	:fields="fields"
							hover
							outlined
							small
							fixed
							@pagination-change="paginationChange"
							:itemsPerPageSelect="{values: [20,50,100]}"
      						:itemsPerPage="limit"
							
					    >   
							<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}</CBadge>
         						 </td>
					      	</template>
					      	<template #action="{item}">
						        <td style="padding: 5px; width: 100px">
						        	<CDropdown 
						                toggler-text="Select"  
										size="sm"
						                color="info"
						            >
										<CDropdownItem v-if="config.getPermission('booking').view" @click="viewModal(item)"><i class="fa fa-eye" > View </i></CDropdownItem>
						            	<CDropdownItem v-if="config.getPermission('booking').update
										&& hide_when(['dispatcher','finance staff','fleet staff','fleet manager','rw hr staff'], //hide button for roadwise only
										['delivered','under review','dispatched'].indexOf(item.status) > -1)" @click="updateModal(item)"> <i class="fa fa-pencil-square-o"> Update </i> </CDropdownItem>  
						                <CDropdownItem @click="openDeliverModal(item)" v-if="item.status == 'dispatched' && config.getPermission('booking').deliver"> <i class="fa fa-paper-plane"> Deliver </i> </CDropdownItem> 
						                <CDropdownItem v-if="config.getPermission('booking').cancel" @click="cancelBooking(item)"> <i class="fa fa-window-close-o"> Cancel </i></CDropdownItem> 
						                <CDropdownItem v-if="config.getPermission('booking').delete" @click="confirmDelete(item)"><i class="fa fa-trash"> Delete </i> </CDropdownItem> 

					
						            </CDropdown>
						        </td>
					      	</template>
					    </CDataTable> 
					    <CPagination
				          	:active-page.sync="currentPageDispatched"
				          	:pages="Math.ceil(dataListDispatched.total / limit)"
				          	:activePage="currentPageDispatched"
				          	@update:activePage="updatePage($event, 'dispatched')"
				        />
				    </CTab>

				    <CTab title="Delivered" @click.native="tabChanged($event, 'delivered')">
				    	<br/>
						
				      	<CDataTable 
				      		:loading="all_isloading"
					      	:items="dataListDelivered.data"
					      	:fields="fields"
							hover
							outlined
							small
							fixed
							@pagination-change="paginationChange"
							:itemsPerPageSelect="{values: [20,50,100]}"
      						:itemsPerPage="limit"
					
					    >   
							<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}</CBadge>
         						 </td>
					      	</template>
					      	<template #action="{item}">
						        <td style="padding: 5px; width: 100px">
						        	<CDropdown 
						                toggler-text="Select"  
										size="sm"
						                color="info"
									
						            >

									<CDropdownItem v-if="config.getPermission('booking').view" @click="viewModal(item)"><i class="fa fa-eye" > View </i></CDropdownItem>
						            	<CDropdownItem v-if="config.getPermission('booking').update
										&& hide_when(['dispatcher','finance staff','fleet staff','fleet manager','rw hr staff'], //hide button for roadwise only
										['delivered','under review','dispatched'].indexOf(item.status) > -1)" 
										 @click="updateModal(item)"> <i class="fa fa-pencil-square-o"> Update </i> </CDropdownItem>  
										
						                <CDropdownItem @click="openCompleteModal(item)" v-if="item.status == 'delivered' && config.getPermission('booking').complete"> <i class="fa fa-list-alt"> Complete </i> </CDropdownItem> 
						                <CDropdownItem v-if="config.getPermission('booking').cancel" @click="cancelBooking(item)"> <i class="fa fa-window-close-o"> Cancel </i></CDropdownItem> 
						                <CDropdownItem v-if="config.getPermission('booking').delete" @click="confirmDelete(item)"><i class="fa fa-trash"> Delete </i> </CDropdownItem>  
						            </CDropdown>
						        </td>
					      	</template>
					    </CDataTable> 
					    <CPagination
				          	:active-page.sync="currentPageDelivered"
				          	:pages="Math.ceil(dataListDelivered.total / limit)"
				          	:activePage="currentPageDelivered"
				          	@update:activePage="updatePage($event, 'delivered')"
				        />
				    </CTab>


				    <CTab title="Completed" @click.native="tabChanged($event, 'completed')">
				    	<br/>
						
				      	<CDataTable 
				      		:loading="all_isloading"
					      	:items="dataListCompleted.data"
					      	:fields="fields"
						    hover
							outlined
							small
							fixed
							@pagination-change="paginationChange"
							:itemsPerPageSelect="{values: [20,50,100]}"
      						:itemsPerPage="limit"
					    >   
							<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}</CBadge>
									<br>
									<small>{{ item.client_billing ? item.client_billing.client_billing_no : '' }}</small>
         						 </td>
					      	</template>
					      	<template #action="{item}">
						        <td style="padding: 5px; width: 100px">
						        	<CDropdown 
						                toggler-text="Select"  
										size="sm"
						                color="info"
						            >
						            	<CDropdownItem v-if="config.getPermission('booking').view" @click="viewModal(item)"><i class="fa fa-eye" > View </i></CDropdownItem>
						            	<CDropdownItem v-if="config.getPermission('booking').update" @click="updateModal(item)"> <i class="fa fa-pencil-square-o"> Update </i> </CDropdownItem>  
										<CDropdownItem  @click="bookAgain(item)"> <i class="fa fa-list-alt"> Book Again </i> </CDropdownItem> 
						                <CDropdownItem v-if="config.getPermission('booking').cancel" @click="cancelBooking(item)"> <i class="fa fa-window-close-o"> Cancel </i></CDropdownItem> 
						                <CDropdownItem v-if="config.getPermission('booking').delete" @click="confirmDelete(item)"><i class="fa fa-trash"> Delete </i> </CDropdownItem>  
						            </CDropdown>
						        </td>
					      	</template>
					    </CDataTable> 
					    <CPagination
				          	:active-page.sync="currentPageCompleted"
				          	:pages="Math.ceil(dataListCompleted.total / limit)"
				          	:activePage="currentPageCompleted"
				          	@update:activePage="updatePage($event, 'completed')"
				        />
				    </CTab>
					<CTab title="Cancelled" @click.native="tabChanged($event, 'cancelled')">
				    	<br/>
						
				      	<CDataTable 
				      		:loading="all_isloading"
					      	:items="dataListCancelled.data"
					      	:fields="fields"
							hover
							outlined
							small
							fixed
							@pagination-change="paginationChange"
							:itemsPerPageSelect="{values: [20,50,100]}"
      						:itemsPerPage="limit"
					    >   
					    >   
							<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}</CBadge>
         						 </td>
					      	</template>
					      	<template #action="{item}">
						        <td style="padding: 5px; width: 100px">
						        	<CDropdown 
						                toggler-text="Select"  
										size="sm"
						                color="info"
						            >
						            	<CDropdownItem v-if="config.getPermission('booking').view" @click="viewModal(item)"><i class="fa fa-eye" > View </i></CDropdownItem>
						                <CDropdownItem v-if="config.getPermission('booking').delete" @click="confirmDelete(item)"><i class="fa fa-trash"> Delete </i> </CDropdownItem> 
						            </CDropdown>
						        </td>
					      	</template>
					    </CDataTable> 
					    <CPagination
				          	:active-page.sync="currentPageCancelled"
				           	:pages="Math.ceil(dataListCancelled.total / limit)"
				          	:activePage="currentPageCancelled"
				          	@update:activePage="updatePage($event, 'cancelled')"
				        />
				    </CTab>
					  <CTab title="Declined" @click.native="tabChanged($event, 'declined')">
				    	<br/>
						
				      	<CDataTable 
				      		:loading="all_isloading"
					      	:items="dataListDeclined.data"
					      	:fields="fields"
							hover
							outlined
							small
							fixed
							@pagination-change="paginationChange"
							:itemsPerPageSelect="{values: [20,50,100]}"
      						:itemsPerPage="limit"
					    >   
						
							<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}</CBadge>
									
         						 </td>
					      	</template>
					      	<template #action="{item}">
						        <td style="padding: 5px; width: 100px">
						        	<CDropdown 
						                toggler-text="Select"  
										size="sm"
						                color="info"
						            >
										<CDropdownItem v-if="config.getPermission('booking').view" @click="viewModal(item)"><i class="fa fa-eye" > View </i></CDropdownItem>
						            	<CDropdownItem v-if="config.getPermission('booking').update" @click="updateModal(item)"> <i class="fa fa-pencil-square-o"> Update </i> </CDropdownItem>  
						                <CDropdownItem v-if="config.getPermission('booking').cancel" @click="cancelBooking(item)"> <i class="fa fa-window-close-o"> Cancel </i></CDropdownItem> 
						                <CDropdownItem v-if="config.getPermission('booking').delete" @click="confirmDelete(item)"><i class="fa fa-trash"> Delete </i> </CDropdownItem> 


						            </CDropdown>
						        </td>
					      	</template>
					    </CDataTable> 
					    <CPagination
				          	:active-page.sync="currentPageDeclined"
				          	:pages="Math.ceil(dataList.total / limit)"
				          	:activePage="currentPageDeclined"
				          	@update:activePage="updatePage($event, 'declined')"
				        />
				    </CTab>



				     
				</CTabs> 
				   </div>
			</CCol>
	    </CRow>
	     
        <ApproveModal :details="selectedRow" @showModal="approveModal = $event; reloadData();" :showModal="approveModal" />
        <DispatchModal :details="selectedRow" @showModal="dispatchModal = $event; reloadData();" :showModal="dispatchModal" />
        <DeliverModal :details="selectedRow" @showModal="deliverModal = $event; reloadData();" :showModal="deliverModal" />
        <CompleteModal :details="selectedRow" @showModal="completeModal = $event; reloadData();" :showModal="completeModal" />
		</CCard>
	</div>
</template>
<script> 
import config from '../config.js';
import axios from '../axios';
import Swal from 'sweetalert2';
import Search from './search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import ApproveModal from './status_modal/approve';
import DispatchModal from './status_modal/dispatch';
import DeliverModal from './status_modal/deliver';
import CompleteModal from './status_modal/complete';
import moment from 'moment';
import '../style.css';

import Loader from '../../loader.mixin.js';

export default {
	mixins: [Loader],
	mounted(){  
		// this.getVendor();
		// this.getDepot();
		this.getData(); 
		
		for(var i = 0; i < document.querySelectorAll('.nav-item').length; i++){
			document.querySelectorAll('.nav-item')[i].onclick = ()=>{
				this.current_tab = document.querySelector(".nav-link.active").innerHTML;
				this.setCount();
			}
		}
		
		
	},
	computed: {
		fields() {
			const field = [...this.fields_holder];
			if(this.is_marvel()) {
				field[4] = {
					key: 'fuel', 
					label: 'Fuel'
				}
			}

			return field;
		}
	},
	data(){
		return{
			msg: 'Loading',
			url : '',
			config,
			isLoading: false,
			all_isloading : false,
			approveModal: false,
			dispatchModal: false,
			deliverModal: false,
			completeModal: false,
			formModal: false,
			showViewModal: false,
			dataList: [], 
			dataListUnderReview: [], 
			dataListApproved: [], 
			dataListDeclined: [], 
			dataListDispatched: [], 
			dataListDelivered: [], 
			dataListCompleted: [], 
			dataListCancelled: [], 
			current_tab: "All",
			count: 0,
			company_type: JSON.parse(window.localStorage.getItem("user_data")).company.company_type.includes("Trucking") ? "trucking" : JSON.parse(window.localStorage.getItem("user_data")).company.company_type,
			active_tab : 0,
			depotName: "", 
			fields_holder: [
				{
					key: 'booking_no', 
					label: 'Booking No.'
				},
				{
					key: 'delivery_date_label', 
					label: 'Delivery Date',
					
				}, 
				{
					key: 'customer_id_label', 
					label: 'Customer',
					
					
				},
				{
					key: 'booking_type_id_label', 
					label: 'Type',
					_style: { width: '80%'}
				},
				{
					key: 'commodity_id_label', 
					label: 'Commodity',
					
				},
				{
					key: 'depot_id_label', 
					label: 'Depot'
				},  
					{
					key: 'origin_id_label', 
					label: 'Origin'
				},  
				// {
				// 	key: 'asset_type', 
				// 	label: 'Asset'
				// },
				{
					key: 'vehicle_type_id_label', 
					label: 'Trucker'
				},  
				
				{
					key: 'asset_no', 
					label: 'Plate No.'
				},  
				
				{
					key: 'client_ref_no_label', 
					label: 'Client Ref No.'
				},
			
				
				
				{
					key: 'status', 
					label: 'Status'
				}, 
				{
					key: 'action',
					label: 'Action',
					_style: { width: '20px'}
				}
			],
			currentPage: 1, 
			currentPageUnderReview: 1,
			currentPageApproved: 1,
			currentPageDeclined: 1,
			currentPageDispatched: 1,
			currentPageDelivered: 1,
			currentPageCompleted: 1,
			currentPageCancelled: 1,

			limit : 20,
			editMode: false,
			selectedRow: {},
			showCollapse: false,
			filter: {
				booking_no: "",
	            booking_type_id: "",
				commodity_id: "",
	            customer_id: "",
	            asset_type: "",
	            origin_id: "",
				depot_id: "",
				vendor_id: "",
				client_ref_no: "",
	            delivery_date_to: new Date(),
	            delivery_date_from: moment(new Date()).subtract(1, 'month')._d, 
	            plate_no: ""
			},
			vendorList: {
				data: []
			},
			depotList: {
				data:[]
			},
			current_type : null
		}
	},
	name: 'Tables',
	components: {Search, Datepicker, vSelect, ApproveModal, DispatchModal, DeliverModal, CompleteModal},
	methods: {
		paginationChange(ev){
			this.limit = ev;
			this.getData(this.current_type)
			
		},
		bookAgain(item) {
			window.open(`/#/main/create_booking/${item.id}/1`, '_blank');
		},
		tabChanged(ev, type) {
			if(ev.target.className != 'nav-link active') return; // if dili tab ang giclick
			this.currentPage = 1;
			this.current_type = type;
			this.getData(type)

		},
		
		setCount(){
			if(this.current_type == null){
				this.count = this.dataList.total
			}
			if(this.current_type == "under review"){
				this.count = this.dataListUnderReview.total
			}
			if(this.current_type == "approved"){
				this.count = this.dataListApproved.total
			}
			if(this.current_type == "declined"){
				this.count = this.dataListDeclined.total
			}
			if(this.current_type == "dispatched"){
				this.count = this.dataListDispatched.total
			}
			if(this.current_type == "delivered"){
				this.count = this.dataListDelivered.total
			}
			if(this.current_type == "completed"){
				this.count = this.dataListCompleted.total
			}
			if(this.current_type == "cancelled"){
				this.count = this.dataListCancelled.total
			}
		},
		reloadData(){
			this.getData(); 
			
		},
		 getBadge (status) {
    	return status === 'completed' ? 'success'
       		 : status === 'under review' ? 'dark'
         	 : status === 'approved' ? 'warning'
			 : status === 'cancelled' ? 'secondary'
			 : status === 'delivered' ? 'primary'
         	 : status === 'dispatched' ? 'info'
             : status === 'declined' ? 'danger' : 'primary'
    },
		updatePage(ev, data){
			this.currentPage = ev;
			this.getData(data);
		},   

		
	    toggleModal(){
	    	this.formModal = !this.formModal;
	    }, 

	    search(event){
	    	this.filter = event; 
			this.getData(this.current_type);

		
	    }, 

	    getData(type = null){  
			this.all_isloading=true;

	    	var booking_type_id = this.filter.booking_type_id;
	    	if(booking_type_id == "all" || booking_type_id == null){
	    		booking_type_id = ""
	    	}
			var commodity_id = this.filter.commodity_id;
	    	if(commodity_id == "All" || commodity_id == null){
	    		commodity_id = ""
	    	}
	    	var customer_id = this.filter.customer_id;
	    	if(customer_id == "All" || customer_id == null){
	    		customer_id = ""
	    	} 
	    	var origin_id = this.filter.origin_id;
	    	if(origin_id == "All" || origin_id == null){
	    		origin_id = ""
	    	} 
			var vendor_id = this.filter.vendor_id;
	    	if(vendor_id == "All" || vendor_id == null){
	    		vendor_id = ""
	    	} 
			var depot_id = this.filter.depot_id;
	    	if(depot_id == "All" || depot_id == null){
	    		depot_id = ""
	    	} 

	    	var delivery_date_from = this.filter.delivery_date_from;
	    	if(delivery_date_from != ""){
	    		delivery_date_from = moment(delivery_date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
	    	}

	    	var delivery_date_to = this.filter.delivery_date_to;
	    	if(delivery_date_to != ""){
	    		delivery_date_to = moment(delivery_date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000;
	    		delivery_date_to = delivery_date_to + 86399; 
	    	}

	    	if(this.filter.booking_no != ""){
	    		delivery_date_from = "";
	    		delivery_date_to = "";
	    	}
			let booking_status = (type) ? 'status='+type : '';

	    	axios.get(config.api_path+'/booking?'+booking_status
			+'&booking_no='+this.filter.booking_no
			+'&booking_type_id='+booking_type_id
			+'&commodity_id='+commodity_id
			+'&customer_id='+customer_id
			+'&asset_type='+this.filter.asset_type
			+'&origin_id='+origin_id
			+'&depot_id='+depot_id
			+'&delivery_date_to='+delivery_date_to
			+'&delivery_date_from='+delivery_date_from
			+'&vendor_id='+vendor_id
			+'&client_ref_no='+this.filter.client_ref_no
			+'&page='+this.currentPage
			+'&limit='+this.limit
			+'&plate_no='+this.filter.plate_no)
	    	.then(response => {
	    		let dataList = response.data; 
				console.log(dataList)
	    		dataList.data = dataList.data.map((value, index)=>{
	    			if(value.booking_type_id){
	    				value.booking_type_id_label = value.booking_type?.setting_name ?? '';
	    			}
	    			else{
	    				value.booking_type_id_label = "N/A"
	    			}

					if(value.commodity_id){
	    				value.commodity_id_label = value.commodity?.setting_name ?? '';
	    			}
	    			else{
	    				value.commodity_id_label = "N/A"
	    			}

	    			if(value.customer_id){
	    				value.customer_id_label = value.customer?.customer_name ?? ''
	    			}
	    			else{
	    				value.customer_id_label = "N/A"
	    			}

	    			if(value.origin_id){
	    				value.origin_id_label = value.origin?.setting_name ?? '';
	    			}
					else{
	    				value.origin_id_label = "N/A"
	    			}
					if(value.depot_id){
	    				value.depot_id_label = value.depot?.setting_name ?? '';
	    			}
	    			else{
	    				value.depot_id_label = "N/A"
	    			}

	    			if(value.destination_id){
	    				value.destination_id_label = value.destination?.setting_name ?? '';
	    			}
	    			else{
	    				value.destination_id_label = "N/A"
	    			}

    				if(value.is_subcon == false || value.is_subcon == 0){
    					value.vehicle_type_id_label = value.company.company_name;
    				}
    				else{
    					if(value.vendor){
    						value.vehicle_type_id_label = value.vendor.vendor_name	
    					}
    					else{
    						value.vehicle_type_id_label = "N/A"
    					}
    				}

    				// value.vehicle_type_id_label = value.vehicle_type.setting_name
	    			// }
	    			// else{
	    			// 	value.vehicle_type_id_label = "N/A"
	    			// }

	    			if(value.vehicle){
	    				value.plate_no = value.vehicle.plate_no
	    			}
	    			else{
	    				value.plate_no = "N/A"
	    			}

	    			if(value.delivery_date){
	    				value.delivery_date = value.delivery_date
	    			}
	    			else{
	    				value.delivery_date = "N/A"
	    			}
	    			
					// value.client_ref_no_label = value.client_ref_no ?? '';
					value.client_ref_no_label = value.client_ref_no ? this.$options.filters.truncate(value.client_ref_no):'-';

	    			
	    			if(value.maker_model_id){
	    				value.maker_model_id_label = value.maker_model.setting_name
	    			}
	    			if(value.with_gps){
	    				value.with_gps_label = "Yes";
	    			}
	    			else{
	    				value.with_gps_label = "No";
	    			}

	    			if(value.delivery_date){
	    				value.delivery_date_label = moment.unix(value.delivery_date).format('MM/DD/YYYY')
	    			}
	    			else{
	    				value.delivery_date_label = "N/A"
	    			}

	    			if(value.asset_type == 'vehicle'){
	    				value.asset_no = value.vehicle ? value.vehicle.plate_no : "N/A"
	    			}
	    			if(value.asset_type == 'equipment'){
	    				value.asset_no = value.equipment ? value.equipment.equipment_prep_no : "N/A"
	    			}
	    			if(value.asset_type == 'furnishing'){
	    				value.asset_no = value.furnishing ? value.furnishing.asset_no : "N/A"
	    			}
	    			value.asset_type = value.asset_type.charAt(0).toUpperCase() + value.asset_type.slice(1)

					value.fuel = value.fuel ? value.fuel : '-';
	    			return value;
	    		}); 
				
				switch(type) {
					case 'under review':
						this.dataListUnderReview = dataList;
						break;
					case 'approved':
					this.dataListApproved = dataList;
					break;
					case 'dispatched':
						this.dataListDispatched = dataList;
						break;
					case 'delivered':
						this.dataListDelivered = dataList;
						break;
					case 'completed':
						this.dataListCompleted = dataList;
						break;
					case 'cancelled':
						this.dataListCancelled = dataList;
						break;
					case 'declined':
						this.dataListDeclined = dataList;
						break;
					default:
						this.dataList = dataList;
				}
	    		this.setCount()
	    	})
			.catch(err => {
				console.error(err)
				this.all_isloading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.all_isloading = false;	
			})
	    },

	   





	    viewModal(item){ 
	    	// this.$router.push('/main/view_booking/'+item.id) 
					window.open('/#/main/view_booking/'+item.id, '_blank');
	    }, 


	

	    cancelBooking(item){
	    	Swal.fire({
				icon:'warning',
				title: 'Are you sure you want to cancel this booking?', 
				showCancelButton: true,
				confirmButtonText: `Yes`, 
			}).then((result) => { 
				if (result.isConfirmed) {
					Swal.fire({
					  title: 'Cancellation Reason',
					  input: 'text',
					  inputAttributes: {
					    autocapitalize: 'off',
					    required: true
					  },
					  showCancelButton: true,
					  confirmButtonText: 'Save',
					  showLoaderOnConfirm: true,
					  preConfirm: (cancellation_reason) => {
					  	return axios.put(config.api_path+'/booking/'+item.id, {
					  		status: "cancelled",
							user_id: config.getUserDetail('id'),
							status_update: true,
							cancellation_reason
					  	})
				    	.then(response => {
				    		
				    		if (response.status == "success") {
					          throw new Error(response.statusText)
					        }
					        return response;
				    	}) 
				    	.catch(error => {
					        Swal.showValidationMessage(
					          `Request failed: ${error}`
					        )
					      }) 
					      
					  },
					  allowOutsideClick: () => !Swal.isLoading()
					}).then((result) => {
					  if (result.isConfirmed) {
					  	this.getData(); 
					    Swal.fire({
							title: 'Success!',
							text: "Booking successfully cancelled.",
							icon: 'success', 
						})
					  }
					})
					
				}
			})  
	    },

	    confirmDelete(item){
	    	Swal.fire({
				icon:'warning',
				title: 'Are you sure you want to delete '+item.booking_no+"?", 
				showCancelButton: true,
				confirmButtonText: `Yes, delete`, 
				reverseButtons: true,
				confirmButtonColor:'red'
			}).then((result) => { 
				if (result.isConfirmed) {
					
					axios.delete(config.api_path+'/booking/'+item.id)
					.then(response=>{
						Swal.fire({
							title: 'Success!',
							text: "Booking "+item.booking_no+" successfully deleted.",
							icon: 'success', 
						})
						this.getData(); 
						this.getDataUnderReview();
						this.getDataApproved();
						this.getDataDeclined();
						this.getDataDispatched();
						this.getDataDelivered();
						this.getDataCompleted();
						this.getDataCancelled();
					})
					
				}
			})  
	    },

	    openApproveModal(item){
	    	this.approveModal = true;
	    	this.selectedRow = item;
	    },

	    openDispatchModal(item){
	    	this.dispatchModal = true;
	    	this.selectedRow = item;
	    },

	    openDeliverModal(item){
	    	this.deliverModal = true;
	    	this.selectedRow = item;
	    },

	    openCompleteModal(item){
			this.$showLoading(true)
			axios.get(config.api_path+"/booking/"+item.id)
			.then(response=>{  
				this.selectedRow =  response.data.data;
			})
			.finally(fin => {
				this.$showLoading(false)
				this.completeModal = true;
	    	
			})
	    	
	    },

	    updateModal(item){
	    	// this.$router.push('/main/update_booking/'+item.id)
			console.log(item)
			if(item.status?.toLowerCase() == 'completed') {
				if(item.client_billing && item.client_billing.status?.toLowerCase() == 'billed') {
					Swal.fire({
						title: 'Update not allowed!',
						html: "This waybill is already <b>BILLED</b> in client billing.",
						icon: 'warning', 
					});

					return;
				}
				
			}
			
			window.open('/#/main/update_booking/'+item.id, '_blank');
	    },

	    download(){
	    	axios.get(config.api_path+'/reports/booking')
	    	.then(response=>{
	    		window.open(config.main_path.replace('/index.php', '')+'/'+response.data.file) 
	    	})
	    }
 	}
}
</script>
